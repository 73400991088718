import * as Sentry from '@sentry/vue';
import Vue from 'vue';
import Router from './utils/Router';
import Request from './utils/Request';

Vue.config.productionTip = false;

if (document.body.dataset.sentryDsn) {
    Sentry.init({
        Vue,
        dsn: document.body.dataset.sentryDsn,
        autoSessionTracking: false,
        beforeSend: (event, hint) => {
            // Évite les bloqueurs de pubs : envoi l'erreur en ajax pour l'envoyer en PHP
            // @see \App\Controller\DefaultController::errorReportingTunnel
            Request.fetchJson(Router.generate('error-reporting-tunnel'), 'POST', event).then(() => null);

            return null;
        },
    });
    Sentry.setUser({username: document.body.dataset.user});
}